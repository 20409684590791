import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Subscription({ size, color, title, ariaHidden }: ComponentProps) {
  return (
    <>
      <BaseSvgStyle
        size={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={ariaHidden}
      >
        {title && <title>{title}</title>}
        <BaseIconPathStyle
          stroke={color}
          d="M16.9504 4.93996C16.9504 5.18002 16.7766 5.38341 16.5493 5.42996H16.4604H13.1304C12.9965 5.42996 12.8804 5.31382 12.8804 5.17996C12.8804 5.0461 12.9965 4.92996 13.1304 4.92996H15.0004H16.0503L15.3886 4.11484C14.6566 3.21306 13.782 2.49706 12.7558 1.98366C11.7241 1.45742 10.6266 1.19996 9.46038 1.19996C7.37945 1.19996 5.60178 1.93146 4.14683 3.38641C2.87688 4.65635 2.15403 6.17371 1.99268 7.94199C1.97391 8.12205 1.84772 8.20996 1.74038 8.20996C1.59963 8.20996 1.47301 8.08756 1.48842 7.91423L0.990381 7.86996L1.48837 7.91478C1.65762 6.03417 2.41486 4.42229 3.78453 3.04292C5.33977 1.48796 7.22203 0.709961 9.45038 0.709961C10.6388 0.709961 11.7561 0.954559 12.8181 1.45264C13.8831 1.95218 14.796 2.64836 15.5702 3.5547L16.4504 4.58517V3.22996V1.60996C16.4504 1.4761 16.5665 1.35996 16.7004 1.35996C16.8342 1.35996 16.9504 1.4761 16.9504 1.60996V4.93996Z"
        />
        <BaseIconPathStyle
          stroke={color}
          d="M2.01041 14.0504L0.45041 12.4704C0.16041 12.1804 0.17041 11.7204 0.45041 11.4304C0.74041 11.1404 1.21041 11.1404 1.49041 11.4304L3.07041 13.0104L4.65041 11.4304C4.94041 11.1404 5.41041 11.1404 5.69041 11.4304C5.97041 11.7204 5.98041 12.1904 5.69041 12.4704L4.11041 14.0504L5.69041 15.6304C5.98041 15.9204 5.98041 16.3904 5.69041 16.6704C5.40041 16.9604 4.94041 16.9604 4.65041 16.6704L3.07041 15.1104L1.49041 16.6704C1.20041 16.9604 0.74041 16.9504 0.45041 16.6704C0.16041 16.3804 0.16041 15.9104 0.45041 15.6204L2.01041 14.0404V14.0504Z"
        />
        <BaseIconPathStyle
          stroke={color}
          d="M11.2104 10.15H11.2004L9.76044 8.7V4.75C9.76044 4.34 9.42044 4 9.01044 4C8.60044 4 8.26044 4.34 8.26044 4.75V8.89C8.26044 9.16 8.37044 9.41 8.55044 9.6L10.1104 11.16L10.1904 11.24C10.3204 11.35 10.4904 11.43 10.6704 11.43C10.7204 11.43 10.7604 11.43 10.8104 11.42C11.1604 11.36 11.4204 11.05 11.4204 10.68C11.4204 10.6 11.4004 10.52 11.3704 10.45C11.3304 10.34 11.2804 10.24 11.2004 10.16L11.2104 10.15Z"
        />
        <BaseIconPathStyle
          stroke={color}
          d="M8.87043 15.6602L9.45043 15.7002C11.2004 15.7002 12.7304 15.1302 14.0304 14.0002C15.1704 13.0002 15.9104 11.7802 16.2504 10.3202C16.3304 9.9602 16.6304 9.7002 16.9904 9.7002C17.4904 9.7002 17.8404 10.1602 17.7404 10.6502C17.3604 12.3802 16.4804 13.8502 15.1204 15.0602C13.5104 16.4902 11.6204 17.2002 9.46043 17.2002L8.88043 17.1702C8.71043 17.1702 8.55043 17.1002 8.43043 17.0002C8.25043 16.8602 8.13043 16.6602 8.13043 16.4102C8.13043 16.1602 8.25043 15.9602 8.43043 15.8202C8.56043 15.7202 8.71043 15.6502 8.88043 15.6502L8.87043 15.6602Z"
        />
      </BaseSvgStyle>
    </>
  );
}

export default Subscription;
